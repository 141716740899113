<script setup lang="ts">
// @ts-nocheck
import { toUpperCase } from 'uri-js/dist/esnext/util'
import { useI18n, useLocaleRoute } from '#i18n'
import PageLayout from '~/layouts/page.vue'
import { useAPIFetch } from '~/composables/use-api-fetch'
import { useMenu } from '~/stores/use-menu'

const router = useRouter()

const { locale } = useI18n()
const localeRoute = useLocaleRoute()
const menuStore = useMenu()
const route = useRouter().currentRoute.value
// menuStore.getProjectMenu(route.params.slug[0])
//
// watch(locale, (value) => {
//   menuStore.getProjectMenu(route.params.slug[0])
// })
// const projectMenu = computed(() => {
//   if (menuStore.projectMenu) {
//     return menuStore.projectMenu
//   } else {
//     return []
//   }
// })
</script>

<template>
  <PageLayout>
    <!--    <div class="overflow-x-scroll no-scrollbar py-6 md:px-32 max-w-[1920px] mx-auto">-->
    <!--      <ul-->
    <!--        v-if="projectMenu && projectMenu.length > 0"-->
    <!--        class="flex gap-x-10 policy-nav text-sm md:text-base whitespace-nowrap font-notoSansGeorgian pr-6"-->
    <!--      >-->
    <!--        <li v-for="menuItem in projectMenu" :key="menuItem.id">-->
    <!--          <NuxtLink-->
    <!--            class="px-4 py-2 border border-gray-100 rounded-lg font-normal"-->
    <!--            :to="-->
    <!--              menuItem.properties?.link[0]?.route?.path-->
    <!--                ? localeRoute(menuItem.properties?.link[0]?.route?.path)-->
    <!--                : localeRoute(menuItem.properties?.link[0]?.url)-->
    <!--            "-->
    <!--            v-if="menuItem.properties?.link && menuItem.properties?.link[0]"-->
    <!--            >{{ menuItem.properties?.link[0]?.title }}-->
    <!--          </NuxtLink>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->

    <div class="mt-8 px-8 md:px-32 max-w-[1920px] mx-auto">
      <slot />
    </div>
  </PageLayout>
</template>
<style scoped lang="scss">
.policy-nav {
  .router-link-active {
    @apply font-bold text-newVisionBlue-900;
  }
}

.page-overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
</style>
